import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { ForwardedRef } from 'react'
import { BoxBtn, LoadedRowProps } from './TableRow'
import { useStakeAmount, useStakeApr, useStakeEarned, useTvlAmount } from './hooks'
import { useCurrency } from 'hooks/Tokens'
import { Box } from 'rebass'
import Row, { RowBetween, RowFlat } from 'components/Row'
import { ThemedText } from 'theme'
import { TextPrimarySpace } from './styles'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import { TokenSortMethod } from './state'

const BoxDtail = styled(Box)`
  padding: 12px 12px;
`

const H5Table = (props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { voteListIndex, voteListLength, vote, stake, withdraw, claim } = props

  const stakeAmount = useStakeAmount(vote)
  const tvlAmount = useTvlAmount(vote)
  const stakeApr = useStakeApr(vote, tvlAmount)
  const stakeEarned = useStakeEarned(vote)

  const [currencyA, currencyB] = [useCurrency(vote.token0) ?? undefined, useCurrency(vote.token1) ?? undefined]

  return (
    <Box>
      <AutoColumn>
        <Row ml="24px" mt={'16px'} mb={'8px'}>
          <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={24} />
          <ThemedText.Main style={TextPrimarySpace} fontWeight={700} fontSize={16} ml="6px">
            {vote.tokenName}
          </ThemedText.Main>
        </Row>
      </AutoColumn>
      <BoxDtail>
        <RowBetween>
          <AutoColumn>
            <Trans>{TokenSortMethod.APR}</Trans>
            <ThemedText.Main fontWeight={700}>{stakeApr ?? 0}%</ThemedText.Main>
          </AutoColumn>

          <AutoColumn justify="flex-end">
            <Trans>{TokenSortMethod.TVL}</Trans>
            <ThemedText.Main fontWeight={700}>{tvlAmount?.toSignificant(2) || 0}</ThemedText.Main>
          </AutoColumn>
        </RowBetween>
      </BoxDtail>
      <BoxDtail>
        <RowBetween>
          <AutoColumn>
            <Trans>{TokenSortMethod.Stake}</Trans>
            <ThemedText.Main fontWeight={700}>{stakeAmount?.toSignificant(4) || 0}</ThemedText.Main>
          </AutoColumn>

          <AutoColumn justify="flex-end">
            <Trans>{TokenSortMethod.Earned}</Trans>
            <ThemedText.Main fontWeight={700}>{stakeEarned?.toSignificant(4) || 0} CAKEX</ThemedText.Main>
          </AutoColumn>
        </RowBetween>
      </BoxDtail>

      <BoxDtail>
        <Row justify={'flex-end'}>
          <BoxBtn className="Stakebox" onClick={() => stake && stake(vote)}>
            <ThemedText.Main className="Stake" fontWeight={700} fontSize={14}>
              Stake
            </ThemedText.Main>
          </BoxBtn>
          <BoxBtn ml={'16px'} className="Withdrawbox" onClick={() => withdraw && withdraw(vote)}>
            <ThemedText.Main fontWeight={700} className="Withdraw" fontSize={14}>
              Withdraw
            </ThemedText.Main>
          </BoxBtn>
          <BoxBtn ml={'16px'} className="Claimbox" onClick={() => claim && claim(vote)}>
            <ThemedText.Main fontWeight={700} className="Claim" fontSize={14}>
              Claim
            </ThemedText.Main>
          </BoxBtn>
        </Row>
      </BoxDtail>
    </Box>
  )
}

export default H5Table
