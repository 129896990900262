import { v2SdkConfig } from '@uniswap/v2-sdk'
import { v2SdkConfigs } from 'constants/v2sdk'
import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
// default config
v2SdkConfig.updateConfig(v2SdkConfigs[5611])

export default function ChainConfigManager({ children }: { children: JSX.Element }) {
  const { chainId } = useWeb3React()
  console.log('chainId', chainId)

  // update sdk config
  useEffect(() => {
    const config = chainId ? v2SdkConfigs[chainId] : undefined

    if (!config) return

    v2SdkConfig.updateConfig(config)
  }, [chainId])

  return children
}
