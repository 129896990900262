import { Trans } from '@lingui/macro'
import { TableDataContainer } from 'components/Table'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { Box } from 'rebass'
import { HeaderRow, LoadedRow, LoadingRow } from './TableRow'
import Modal from 'components/Modal'
import { AutoColumn } from 'components/Column'
import { ThemedText } from 'theme'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { useInputAmount } from 'hooks/useInputAmount'
import { useCurrency, useToken } from 'hooks/Tokens'
import Row from 'components/Row'
import { useDynamicApprove } from 'components/DynamicApprove'
import { ButtonPrimary } from 'components/Button'
import { useRewordPool } from 'hooks/useContract'
import { ItemInfoProps, useStakeAmount } from './hooks'
import { useWeb3React } from '@web3-react/core'
import { useTransactionAdder } from 'state/transactions/hooks'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { getContract } from 'utils'
import { abi as masterChefABI } from 'abis/masterChef.json'
import H5Table from './H5Table'
import { useIsMobile } from 'hooks/useIsMobile'
const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1248px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoTokensState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoTokenDisplay>{message}</NoTokenDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = 10 }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TableDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TableDataContainer>
    </GridContainer>
  )
}

export default function PositionTable() {
  const loadingVotes = true
  const isMobile = useIsMobile()

  // const voteList = []
  const voteList = [
    {
      tokenName: 'CAKE-USDC',
      token0: '0x9E19eB434fd5cb9B6A6AaFC06cb024d9f6e12521',
      token1: '0xB69B8B9f40c7427A1105C9a7E26f206976c4e36D',
      stakeToken: '0xBD0fD00a920e7Fe6DD98bA798C0e2FbfD851e7E8',
      pool: '0x4a50a22b9b78970c92ddc8cebd76434d1107d84f',
      pid: '0',
    },
  ]

  const { account, library } = useWeb3React()

  const [selectPool, setselectPool] = useState<ItemInfoProps | undefined>()

  const depositedCurrencyAmount = useStakeAmount(selectPool)

  const poolContract = useRewordPool(selectPool?.pool)
  const addTransaction = useTransactionAdder()

  const Depositcurrency = useCurrency(selectPool?.stakeToken)
  const [loadingDeposit, setLoadingDeposit] = useState(false)
  const [loadingWithdraw, setLoadingWithdraw] = useState(false)
  const {
    inputAmount: inputAmountDeposit,
    isInputValid: isInputValidDeposit,
    inputError: inputErrorDeposit,
    handleUserInput: handleUserInputDeposit,
    handleResetInput: handleResetInputDeposit,
    handleMax: handleMaxDeposit,
  } = useInputAmount(Depositcurrency)

  const {
    inputAmount: inputAmountWithdraw,
    isInputValid: isInputValidWithdraw,
    inputError: inputErrorWithdraw,
    handleUserInput: handleUserInputWithdraw,
    handleResetInput: handleResetInputWithdraw,
    handleMax: handleMaxWithdraw,
  } = useInputAmount(depositedCurrencyAmount?.currency, depositedCurrencyAmount)

  const { DynamicApprove, isApproved, approveError } = useDynamicApprove([inputAmountDeposit.amount], selectPool?.pool)

  const [isReadyWithdraw, reasonWithdraw] = useMemo(() => {
    const isReady = isInputValidWithdraw && !loadingWithdraw

    const reason = inputErrorWithdraw

    return [isReady, reason]
  }, [inputErrorWithdraw, isInputValidWithdraw, loadingWithdraw])
  const [isReadyDeposit, reasonDeposit] = useMemo(() => {
    const _reason: React.ReactNode | undefined = inputErrorDeposit || approveError
    const _isReady = isInputValidDeposit && isApproved && !loadingDeposit

    return [_isReady, _reason]
  }, [inputErrorDeposit, approveError, isInputValidDeposit, isApproved, loadingDeposit])

  const [depositOpen, setDepositOpen] = useState(false)
  const [withdrawOpen, setWithdrawOpen] = useState(false)

  const handleDeposit = useCallback(async () => {
    const _amount_in = inputAmountDeposit.amount?.quotient?.toString()
    if (!_amount_in || !poolContract || !selectPool || !account) {
      return
    }
    setLoadingDeposit(true)
    try {
      const data = await poolContract.deposit(selectPool.pid, _amount_in)

      addTransaction(data, {
        type: 15,
      })
      handleResetInputDeposit()
    } catch (error) {
      console.log('error')
    } finally {
      setLoadingDeposit(false)
    }
  }, [account, addTransaction, handleResetInputDeposit, inputAmountDeposit.amount?.quotient, poolContract, selectPool])

  const handleWithdraw = useCallback(async () => {
    const _amount_in = inputAmountWithdraw.amount?.quotient?.toString()
    if (!_amount_in || !poolContract || !selectPool || !account) {
      return
    }
    setLoadingWithdraw(true)
    try {
      const data = await poolContract.withdraw(selectPool.pid, _amount_in)

      addTransaction(data, {
        type: 16,
      })
      handleResetInputWithdraw()
    } catch (error) {
      console.log('error')
    } finally {
      setLoadingWithdraw(false)
    }
  }, [
    account,
    addTransaction,
    handleResetInputWithdraw,
    inputAmountWithdraw.amount?.quotient,
    poolContract,
    selectPool,
  ])

  const showDeposit = useCallback((data: any) => {
    setselectPool(data)
    setDepositOpen(true)
  }, [])
  const showWithdraw = useCallback((data: any) => {
    setselectPool(data)
    setWithdrawOpen(true)
  }, [])

  const claimToken = useCallback(
    async (dataInfo: any) => {
      if (!library || !account) return
      try {
        const pool = getContract(dataInfo.pool, masterChefABI, library, account)
        const data = await pool.withdraw(dataInfo.pid, 0)
        addTransaction(data, {
          type: 16,
        })
      } catch (error) {
        console.log('error')
      }
    },
    [account, addTransaction, library]
  )
  /* loading and error state */
  if (loadingVotes && !voteList) {
    return <LoadingTokenTable rowCount={10} />
  } else if (!voteList) {
    return (
      <NoTokensState
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading voteList. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (voteList?.length === 0) {
    return <NoTokensState message={<Trans>No voteList found</Trans>} />
  } else {
    if (isMobile) {
      return (
        <Box>
          {voteList.map((vote, index) => (
            <H5Table
              key={index}
              voteListIndex={index}
              voteListLength={voteList.length}
              vote={vote}
              stake={showDeposit}
              withdraw={showWithdraw}
              claim={claimToken}
            />
          ))}
        </Box>
      )
    }

    return (
      <>
        <HeaderRow />
        <TableDataContainer>
          {voteList.map((vote, index) => (
            <LoadedRow
              key={index}
              voteListIndex={index}
              voteListLength={voteList.length}
              vote={vote}
              stake={showDeposit}
              withdraw={showWithdraw}
              claim={claimToken}
            />
          ))}
        </TableDataContainer>
        <Modal
          isOpen={depositOpen}
          onDismiss={() => {
            handleResetInputDeposit()
            setDepositOpen(false)
          }}
          maxHeight={90}
        >
          <Box sx={{ width: '420px', padding: [24], background: '' }}>
            <AutoColumn gap="md">
              <ThemedText.Main fontSize={23}>Deposit</ThemedText.Main>
              <CurrencyInputPanel
                value={inputAmountDeposit.value}
                onUserInput={(_value) => {
                  handleUserInputDeposit(_value)
                }}
                showMaxButton={true}
                onMax={handleMaxDeposit}
                currency={Depositcurrency}
                id="amount-input"
              />
            </AutoColumn>

            <Row justify="center" sx={{ mt: [14] }}>
              <DynamicApprove />
              <ButtonPrimary
                sx={{ border: '0 !important', ml: ['8px'], color: '' }}
                disabled={!isReadyDeposit}
                onClick={handleDeposit}
              >
                <Trans>Deposit</Trans>
              </ButtonPrimary>
            </Row>
          </Box>
        </Modal>

        <Modal
          isOpen={withdrawOpen}
          onDismiss={() => {
            handleResetInputWithdraw()
            setWithdrawOpen(false)
          }}
          maxHeight={90}
        >
          <Box sx={{ width: '420px', padding: [24], background: '' }}>
            <AutoColumn gap="md">
              <ThemedText.Main fontSize={23}>Withdraw</ThemedText.Main>
              <CurrencyInputPanel
                value={inputAmountWithdraw.value}
                onUserInput={(_value) => {
                  handleUserInputWithdraw(_value)
                }}
                renderBalance={() => (
                  <ThemedText.Main
                    variant="gray"
                    fontWeight={400}
                    fontSize={14}
                    className="text-show-significant"
                    style={{ display: 'inline' }}
                  >
                    <Trans>Balance</Trans>: {depositedCurrencyAmount?.toSignificant(6)} LP
                  </ThemedText.Main>
                )}
                showMaxButton={true}
                onMax={() => {
                  handleMaxWithdraw(depositedCurrencyAmount)
                }}
                currency={depositedCurrencyAmount?.currency}
                id="amount-input"
              />
            </AutoColumn>

            <Row justify="center" sx={{ mt: [14] }}>
              <DynamicApprove />
              <ButtonPrimary
                sx={{ border: '0 !important', ml: ['8px'], color: '' }}
                disabled={!isReadyWithdraw}
                onClick={handleWithdraw}
              >
                Withdraw
              </ButtonPrimary>
            </Row>
          </Box>
        </Modal>
      </>
    )
  }
}
