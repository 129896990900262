import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useRewordPool, useTokenContract } from 'hooks/useContract'
import { useMemo } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { BN, fromBN, toFromBN } from 'utils/bn'

export type ItemInfoProps = {
  tokenName: string
  token0: string
  token1: string
  stakeToken: string
  pool: string
  pid: string
}

export function useStakeAmount(data?: ItemInfoProps) {
  const { account, chainId } = useWeb3React()
  const tokenContract = useRewordPool(data?.pool)
  const { result } = useSingleCallResult(tokenContract, 'userInfo', [data?.pid, account ?? undefined])
  return useMemo(() => {
    if (!result || !data || !data?.pool || !chainId) return
    const token = new Token(chainId, data.pool, 18, 'LP', 'LP')
    return CurrencyAmount.fromRawAmount(token, result?.amount)
  }, [chainId, data, result])
}

export function useTvlAmount(data?: ItemInfoProps) {
  const { chainId } = useWeb3React()
  const RewordPoolCotract = useTokenContract(data?.stakeToken)

  const { result } = useSingleCallResult(RewordPoolCotract, 'balanceOf', [data?.pool ?? undefined])

  return useMemo(() => {
    if (!result || !data || !data?.pool || !chainId) return
    const token = new Token(chainId, data.pool, 18, 'LP', 'LP')
    return CurrencyAmount.fromRawAmount(token, result?.[0])
  }, [chainId, data, result])
}

export function useStakeApr(data?: ItemInfoProps, AllTvl?: CurrencyAmount<Token>) {
  const RewordPoolCotract = useRewordPool(data?.pool)

  const tokenPrice = BN(1)

  const { result: poolInfo } = useSingleCallResult(RewordPoolCotract, 'poolInfo', [data?.pid])
  const { result: allPoint } = useSingleCallResult(RewordPoolCotract, 'totalAllocPoint')
  const { result: tokenPerShre } = useSingleCallResult(RewordPoolCotract, 'cakeXPerSecond')
  console.log('[tokenPerShre]:', tokenPerShre)
  return useMemo(() => {
    if (!poolInfo || !allPoint || !tokenPerShre || !AllTvl) return
    const currentPoint = BN(poolInfo.allocPoint.toString()).div(allPoint[0].toString())
    const tokenPer = toFromBN(tokenPerShre[0])
    const tvl = BN(AllTvl.toExact())
    const apr = tokenPer.times(365).times(86400).times(tokenPrice).div(tvl).times(currentPoint).times(100)
    return apr.toFixed(2)
  }, [AllTvl, allPoint, poolInfo, tokenPerShre, tokenPrice])
}

export function useStakeEarned(data?: ItemInfoProps) {
  const { account, chainId } = useWeb3React()

  const RewordPoolCotract = useRewordPool(data?.pool)
  const parms = useMemo(() => {
    if (!data || !account) return [undefined]
    return [data.pid, account]
  }, [account, data])
  const { result } = useSingleCallResult(RewordPoolCotract, 'pendingCakeX', parms)

  return useMemo(() => {
    if (!result || !data || !data?.pool || !chainId) return
    const token = new Token(chainId, data.pool, 18, 'LP', 'LP')
    return CurrencyAmount.fromRawAmount(token, result?.[0])
  }, [chainId, data, result])
}
