import { Box } from 'rebass'
import PositionTable from './Table'
import styled from 'styled-components'
import { MEDIA_WIDTHS } from 'theme'

const FarmBox = styled(Box)`
  width: 1248px;
  border: ${({ theme }) => `1px solid ${theme.text5}`};
  background-color: ${({ theme }) => theme.bg0};
  border-radius: 16px;
  @media screen and (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    width: 100%;
  }
`

const Farm = () => {
  return (
    <FarmBox>
      <PositionTable></PositionTable>
    </FarmBox>
  )
}

export default Farm
