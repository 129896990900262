import { Trans } from '@lingui/macro'
import { AutoColumn } from 'components/Column'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useState } from 'react'
import { Box } from 'rebass'
import { ThemedText } from 'theme'

import { LongIcon, PoolCell, StyledHeaderRow, StyledRow, TextLeftCell, TextPrimarySpace, TextRightCell } from './styles'
import { TokenSortMethod } from './state'
import { color } from 'd3'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { useCurrency, useToken } from 'hooks/Tokens'
import { Currency } from '@uniswap/sdk-core'
import { unwrappedToken } from 'utils/unwrappedToken'
import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList'
import styled from 'styled-components'
import { ItemInfoProps, useStakeAmount, useStakeApr, useStakeEarned, useTvlAmount } from './hooks'

export const BoxBtn = styled(Box)`
  padding: 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  cursor: pointer;
  &.Stakebox {
    &:hover {
      background-color: #2172e5;
    }
  }
  &.Withdrawbox {
    &:hover {
      background-color: #ffad20;
    }
  }
  &.Claimbox {
    &:hover {
      background-color: #00b611;
      color: #fff;
    }
  }

  .Claim {
    color: #00b611;
    &:hover {
      color: #fff;
    }
  }

  .Stake {
    color: #2172e5;

    &:hover {
      color: #fff;
      background-color: #2172e5;
    }
  }
  .Withdraw {
    color: #ffad20;
    &:hover {
      color: #fff;
      background-color: #ffad20;
    }
  }
`

/* Token Row: skeleton row component */
function PositionRow({
  header,
  Pool,
  APR,
  TVL,
  Stake,
  Earned,
  Acitons,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  Pool: ReactNode
  APR: ReactNode
  TVL: ReactNode
  Stake: ReactNode
  Earned: ReactNode
  Acitons: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <PoolCell data-testid="pool-cell">{Pool}</PoolCell>
      <TextRightCell data-testid="tvl-cell">{APR}</TextRightCell>
      <TextLeftCell data-testid="vApr-cell">{TVL}</TextLeftCell>
      <TextRightCell data-testid="fees-cell">{Stake}</TextRightCell>
      <TextRightCell data-testid="bribes-cell">{Earned}</TextRightCell>
      <TextRightCell data-testid="total-votes-cell">{Acitons}</TextRightCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="vote-header-row">{rowCells}</StyledHeaderRow>
  return <StyledRow {...rest}>{rowCells}</StyledRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <PositionRow
      header={true}
      Pool={
        <Box pl="16px">
          <Trans>{TokenSortMethod.Pool}</Trans>
        </Box>
      }
      APR={
        <Row>
          <Trans>{TokenSortMethod.APR}</Trans>
        </Row>
      }
      TVL={
        <Row>
          <Trans>{TokenSortMethod.TVL}</Trans>
        </Row>
      }
      Stake={
        <Row>
          <Trans>{TokenSortMethod.Stake}</Trans>
        </Row>
      }
      Earned={
        <Row>
          <Trans>{TokenSortMethod.Earned}</Trans>
        </Row>
      }
      Acitons={
        <Row>
          <Trans>{TokenSortMethod.operation}</Trans>
        </Row>
      }
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <PositionRow
      header={false}
      $loading
      Pool={
        <Row>
          <IconLoadingBubble width="48px" height="48px" />
          <MediumLoadingBubble />
        </Row>
      }
      APR={
        <Row flexDirection="column">
          <MediumLoadingBubble />
        </Row>
      }
      TVL={
        <Row>
          <LoadingBubble />
        </Row>
      }
      Stake={
        <Row flexDirection="column">
          <MediumLoadingBubble />
        </Row>
      }
      Earned={
        <Row flexDirection="column">
          <MediumLoadingBubble />
        </Row>
      }
      Acitons={
        <Row flexDirection="column">
          <MediumLoadingBubble />
        </Row>
      }
      {...props}
    />
  )
}

export interface LoadedRowProps {
  voteListIndex: number
  voteListLength: number
  vote: ItemInfoProps

  stake: (data: any) => void
  withdraw: (data: any) => void
  claim: (data: any) => void
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { voteListIndex, voteListLength, vote, stake, withdraw, claim } = props

  const stakeAmount = useStakeAmount(vote)
  const tvlAmount = useTvlAmount(vote)
  const stakeApr = useStakeApr(vote, tvlAmount)
  const stakeEarned = useStakeEarned(vote)

  const [currencyA, currencyB] = [useCurrency(vote.token0) ?? undefined, useCurrency(vote.token1) ?? undefined]

  return (
    <div ref={ref} data-testid={`vote-table-row-${vote.tokenName}`}>
      <PositionRow
        header={false}
        Pool={
          <AutoColumn>
            <Row ml="16px">
              <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={24} />
              <ThemedText.Main style={TextPrimarySpace} fontWeight={700} fontSize={16} ml="6px">
                {vote.tokenName}
              </ThemedText.Main>
            </Row>
          </AutoColumn>
        }
        APR={
          <AutoColumn>
            <ThemedText.Main fontWeight={700}>{stakeApr ?? 0}%</ThemedText.Main>
          </AutoColumn>
        }
        TVL={
          <AutoColumn>
            <ThemedText.Main fontWeight={700}>{tvlAmount?.toSignificant(2) || 0}</ThemedText.Main>
          </AutoColumn>
        }
        Stake={
          <AutoColumn>
            <ThemedText.Main fontWeight={700}>{stakeAmount?.toSignificant(4) || 0}</ThemedText.Main>
          </AutoColumn>
        }
        Earned={
          <AutoColumn>
            <ThemedText.Main fontWeight={700}>{stakeEarned?.toSignificant(4) || 0} CAKEX</ThemedText.Main>
          </AutoColumn>
        }
        Acitons={
          <Row>
            <BoxBtn className="Stakebox" onClick={() => stake && stake(vote)}>
              <ThemedText.Main className="Stake" fontWeight={700} fontSize={14}>
                Stake
              </ThemedText.Main>
            </BoxBtn>
            <BoxBtn ml={'16px'} className="Withdrawbox" onClick={() => withdraw && withdraw(vote)}>
              <ThemedText.Main fontWeight={700} className="Withdraw" fontSize={14}>
                Withdraw
              </ThemedText.Main>
            </BoxBtn>
            <BoxBtn ml={'16px'} className="Claimbox" onClick={() => claim && claim(vote)}>
              <ThemedText.Main fontWeight={700} className="Claim" fontSize={14}>
                Claim
              </ThemedText.Main>
            </BoxBtn>
          </Row>
        }
        first={voteListIndex === 0}
        last={voteListIndex === voteListLength - 1}
      />
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
