import { V2SdkConfigProps } from '@uniswap/v2-sdk/dist/config'
import { SupportedChainId } from './chains'

export const v2SdkConfigs: {
  [chainId: number]: V2SdkConfigProps
} = {
  [SupportedChainId.OPBNB_MAIN]: {
    factoryAddress: '0xF72Ad9009e81181E342153b20e8afe8aD21DA3e7',
    initCodeHash: '0xd2ea59368db9301dc1e2eb8ddfd8fa01342e270bed3aeb5214e943affd8d8781',
  },
  [SupportedChainId.OPBNB_TEST]: {
    factoryAddress: '0xe74593F6937c2a836560B1E7D2836979737439f4',
    initCodeHash: '0xd2ea59368db9301dc1e2eb8ddfd8fa01342e270bed3aeb5214e943affd8d8781',
  },
}
