import { Cell, StyledTableRow } from 'components/Table'

import { Box } from 'rebass'
import styled, { css } from 'styled-components/macro'

export const MAX_WIDTH_MEDIA_BREAKPOINT = '1248px'
export const XLARGE_MEDIA_BREAKPOINT = '960px'
export const LARGE_MEDIA_BREAKPOINT = '840px'
export const MEDIUM_MEDIA_BREAKPOINT = '720px'
export const SMALL_MEDIA_BREAKPOINT = '540px'
export const MOBILE_MEDIA_BREAKPOINT = '420px'

export const StyledRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: flex-start;
  grid-template-columns: 1.3fr 1.4fr 1.3fr 1.3fr 1.2fr 1.5fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  min-height: 52px;
  ${({ last, theme }) => css`
    border-bottom: ${last ? 'none' : `1px solid rgba(0, 0, 0, 0.08)`};
  `}

  transition: background-color  250ms ease;
  transition-duration: 125ms;

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: rgba(0, 0, 0, 0.05);
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  .bg {
    ${({ theme }) =>
      css`
        background-color: 'rgba(0, 0, 0, 0.05)';
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.3fr 1.4fr 1.3fr 1.3fr 1.2fr 1.2fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.3fr 1.4fr 1.3fr 1.3fr 1.2fr 1.2fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.3fr 1.4fr 1.3fr 1.3fr 1.2fr 1.2fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 1.3fr 1.4fr 1.3fr 1.3fr 1.2fr 1.2fr;
    min-width: unset;
    border-bottom: 0.5px solid #131a2a;

    :last-of-type {
      border-bottom: none;
    }
  }
`
export const StyledHeaderRow = styled(StyledRow)`
  justify-content: center;
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

export const TextPrimarySpace: any = { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }

export const PoolCell = styled(Cell)`
  justify-content: flex-start;
  padding: 12px 0px;
  min-width: 100px;
  overflow: hidden;
  gap: 8px;
  height: 100%;
`

export const LongIcon = styled(Box)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  background-color: rgba(0, 209, 209, 1);
  border-radius: 50%;
  line-height: 14px;
  text-align: center;
`

export const TextLeftCell = styled(Cell)`
  justify-content: flex-start;
  padding: 12px 0px;
  height: 100%;
  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
export const TextRightCell = styled(Cell)`
  padding: 12px 0px;
  height: 100%;
  justify-content: flex-start;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
